import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/axios';
import { ISingleElement } from '../../interfaces/common.interface';
import { addNotification } from '../app/reducer';
import {
  ICreateTask,
  ITask,
  IUpdateTaskOrder,
} from '../../interfaces/task.interface';

export const tasksApi = createApi({
  reducerPath: 'tasksApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Tasks'],
  endpoints: (builder) => ({
    createTask: builder.mutation<ISingleElement<ITask>, ICreateTask>({
      query: (data) => {
        return {
          url: '/tasks',
          method: 'POST',
          data,
        };
      },
      invalidatesTags: () => [{ type: 'Tasks', id: 'LIST' }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              title: 'Task created successfully',
              type: 'success',
            }),
          );
        } catch (err) {
          dispatch(
            addNotification({
              title: 'Task creation error',
              type: 'error',
            }),
          );
        }
      },
    }),

    getTasks: builder.query<ISingleElement<ITask[]>, { id: string }>({
      query: ({ id }) => ({
        url: `/tasks/${id}`,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }: { id: number }) => ({
                type: 'Tasks' as const,
                id,
              })),
              { type: 'Tasks', id: 'LIST' },
            ]
          : [{ type: 'Tasks', id: 'LIST' }],
    }),

    updateTaskOrder: builder.mutation<
      { data: { message: string } },
      { data: IUpdateTaskOrder[] }
    >({
      query: (data) => {
        return {
          url: '/tasks/order',
          method: 'PATCH',
          data,
        };
      },
      invalidatesTags: () => [{ type: 'Tasks', id: 'LIST' }],
    }),

    deleteTask: builder.mutation<{ message: string }, { id: number }>({
      query: (data) => {
        return {
          url: `/tasks/${data.id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (_, __, data) => [{ type: 'Tasks', id: data.id }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              title: 'Task removed successfully',
              type: 'success',
            }),
          );
        } catch (err) {
          dispatch(
            addNotification({
              title: 'Task removing error',
              type: 'error',
            }),
          );
        }
      },
    }),

    updateTask: builder.mutation<{ data: { message: string } }, ITask>({
      query: (data) => {
        return {
          url: `/tasks/${data.id}`,
          method: 'PATCH',
          data,
        };
      },
      invalidatesTags: () => [{ type: 'Tasks' }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            addNotification({
              title: 'Task updated successfully',
              type: 'success',
            }),
          );
        } catch (err) {
          dispatch(
            addNotification({
              title: 'Task updating error',
              type: 'error',
            }),
          );
        }
      },
    }),
  }),
});

export const {
  useCreateTaskMutation,
  useGetTasksQuery,
  useUpdateTaskOrderMutation,
  useDeleteTaskMutation,
  useUpdateTaskMutation,
} = tasksApi;
