import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import rootReducer from './root-reducer';

import { categoryApi } from './category/api';
import { usersApi } from './user/api';
import { wishlistApi } from './wishlist/api';
import { positionApi } from './position/api';
import { knowledgeBaseApi } from './knowledge-base/api';
import { postApi } from './post/api';
import { reportApi } from './report/api';
import { projectApi } from './project/api';
import { roleApi } from './role/api';
import { technologyApi } from './technology/api';
import { salesContactApi } from './sales-contact/api';
import { salesRosterApi } from './roster-employee/api';
import { rosterProjectApi } from './roster-project/api';
import { permissionsApi } from './permissions/api';
import { timelineApi } from './timeline/api';
import { servicesApi } from './services/api';
import { vacationApi } from './vacation/api';
import { widgetsApi } from './widgets/api';
import { fileApi } from './file/api';
import { faqApi } from './faq/api';
import { equipmentApi } from './equipment/api';
import { tasksApi } from './tasks/api';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const middleWares: any = [
  categoryApi.middleware,
  widgetsApi.middleware,
  usersApi.middleware,
  wishlistApi.middleware,
  positionApi.middleware,
  knowledgeBaseApi.middleware,
  postApi.middleware,
  reportApi.middleware,
  projectApi.middleware,
  permissionsApi.middleware,
  technologyApi.middleware,
  salesContactApi.middleware,
  salesRosterApi.middleware,
  rosterProjectApi.middleware,
  roleApi.middleware,
  timelineApi.middleware,
  servicesApi.middleware,
  vacationApi.middleware,
  fileApi.middleware,
  faqApi.middleware,
  equipmentApi.middleware,
  tasksApi.middleware,
];

if (process.env.NODE_ENV === 'development') {
  middleWares.push(createLogger({ collapsed: true }));
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(...middleWares),
});

export default store;

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
